import ProjectCard from "../components/ProjectCard";
import useProjects from "../customHooks/useProjects";
import StyledPortfolio from "./styles/StyledPortfolio";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";

const Portfolio = () => {
  const getSpans = (text) => {
    let spans = [];
    for (let i = 0; i < text.length; i++) {
      spans.push(text[i]);
    }
    return spans;
  };
  const projects = useProjects();
  return (
    <StyledPortfolio>
      <h1 className="title">
        {getSpans("Portfolio").map((e, i) => (
          <span className="t" key={i}>
            {e}
          </span>
        ))}
      </h1>
      <div className="proj-container">
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            title={project.title}
            description={project.description}
            image={project.image}
            link={project.link}
            github={project.github}
          />
        ))}
      </div>
      <div id="projectFacts" className="sectionClass">
        <div className="fullWidth eight columns">
          <div className="projectFactsWrap ">
            <div
              className="item wow fadeInUpBig animated animated"
              data-number="12"
            >
              <BusinessCenterOutlinedIcon className="stats-icon" />
              <p id="number1" className="number">
                17
              </p>
              <span></span>
              <p>Projects Completed</p>
            </div>
            <div
              className="item wow fadeInUpBig animated animated"
              data-number="55"
            >
              <SentimentSatisfiedAltIcon className="stats-icon" />
              <p id="number2" className="number">
                10
              </p>
              <span></span>
              <p>Happy Clients</p>
            </div>
            <div
              className="item wow fadeInUpBig animated animated"
              data-number="359"
            >
              <AccessTimeIcon className="stats-icon" />
              <p id="number3" className="number">
                1500+
              </p>
              <span></span>
              <p>Spent Hours</p>
            </div>
            <div
              className="item wow fadeInUpBig animated animated"
              data-number="246"
            >
              <SchoolOutlinedIcon className="stats-icon" />
              <p id="number4" className="number">
                7
              </p>
              <span></span>
              <p>Certifications</p>
            </div>
          </div>
        </div>
      </div>
    </StyledPortfolio>
  );
};

export default Portfolio;
