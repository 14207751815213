import { Suspense } from "react";
import Contact3d from "../sections/Contact3d";
import ContactForm from "../sections/ContactForm";
import StyledContact from "./styles/StyledContact";
import gmail from "../assets/icons/gmail.png";
import instagram from "../assets/icons/instagram.svg";
import linkedin from "../assets/icons/linkedin.svg";
import github from "../assets/icons/github.jpg";
import facebook from "../assets/icons/facebook.svg";
import twitter from "../assets/icons/twitter.svg";
import whatsapp from "../assets/icons/whatsapp.png";
import call from "../assets/icons/call.png";

const ContactPage = () => {
  const getSpans = (text) => {
    let spans = [];
    for (let i = 0; i < text.length; i++) {
      spans.push(text[i]);
    }
    return spans;
  };
  return (
    <StyledContact>
      <h1 className="contact-title">
        {getSpans("Contact me").map((e, i) => (
          <span className="t" key={i}>
            {e}
          </span>
        ))}
      </h1>
      <div className="contact-contents">
        <ContactForm />
        <Suspense fallback={<div>Loading...</div>}>
          <Contact3d />
        </Suspense>
      </div>
      <div className="d-flex">
        <h2>Let's connect and chat!</h2>
      </div>
      <div className="media-icons">
        <a href="tel:+91-9925565507" rel="noreferrer">
          <img
            className="media-icon"
            width={30}
            height={30}
            src={call}
            alt="Call"
          />
        </a>
        <a
          target="_blank"
          href="https://api.whatsapp.com/send?phone=9925565507"
          rel="noreferrer"
        >
          <img
            className="media-icon"
            width={30}
            height={30}
            src={whatsapp}
            alt="Whatsapp"
          />
        </a>
        <a target="_blank" href="mailto:arjunkhetia@gmail.com" rel="noreferrer">
          <img
            className="media-icon"
            width={30}
            src={gmail}
            alt="Gmail"
          />
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/arjunkhetia"
          rel="noreferrer"
        >
          <img
            className="media-icon"
            width={30}
            height={30}
            src={facebook}
            alt="Facebook"
          />
        </a>
        <a
          target="_blank"
          href="https://github.com/arjunkhetia"
          rel="noreferrer"
        >
          <img
            className="media-icon"
            width={30}
            src={github}
            alt="Github"
          />
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/in/arjun-khetia-32527a54"
          rel="noreferrer"
        >
          <img
            className="media-icon"
            width={30}
            height={30}
            src={linkedin}
            alt="Linkedin"
          />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/arjunkhetia"
          rel="noreferrer"
        >
          <img
            className="media-icon"
            width={30}
            height={30}
            src={instagram}
            alt="Instagram"
          />
        </a>
        <a
          target="_blank"
          href="https://twitter.com/arjunkhetia"
          rel="noreferrer"
        >
          <img
            className="media-icon"
            width={30}
            height={30}
            src={twitter}
            alt="Twitter"
          />
        </a>
      </div>
    </StyledContact>
  );
};

export default ContactPage;
