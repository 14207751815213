import styled from "styled-components";
import { type, screenSize } from "../../utils/typography";

const StyledExperience = styled.div`
  .experience-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .experience-title {
    font-size: ${type.webTitle};
    padding-left: 2.25rem;
    padding-right: 0;
    margin: 0;
    padding-bottom: 0rem;
    margin-bottom: 2rem;
  }

  .cards {
    position: relative;
    width: 100%;
    height: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: ${screenSize.tablet}) {
    .experience-container {
      height: auto;
    }

    .experience-title {
      font-size: calc(${type.webTitle} * 0.8);
    }
  }

  @media only screen and (max-width: ${screenSize.mobile}) {
    .experience-container {
      height: 40rem; 
    }

    .cards {
      padding: 0;
      padding-bottom: 0.5rem;
      width: 100%;
      height: 30rem;
    }
  
    .experience-title {
      font-size: ${type.webTitleMobile};
      padding: 1.1rem;
      padding-top: 2rem;
    }
  }
`;

export default StyledExperience;
