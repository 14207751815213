import "./styles/navbar.css";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Logo from "../components/logo";
import { NavButton } from "../components/nav-button";

export const NavBar = () => {
  const toggleVisibility = () => {
    const el = document.querySelector(".navbar");
    el.style.display = "none";
    const se = document.querySelector(".menu-icon");
    se.style.display = "block";
  };

  return (
    <section className="navbar">
      <button className="icon-close" onClick={toggleVisibility}>
        <CloseIcon />
      </button>
      <div className="logo">
        <Logo />
      </div>
      <div className="nav-buttons">
        <ul>
          <li className="nav-tile">
            <NavButton value="Home" icon={<HomeOutlinedIcon />} />
          </li>
          <li className="nav-tile">
            <NavButton value="About" icon={<InfoOutlinedIcon />} />
          </li>
          <li className="nav-tile">
            <NavButton value="Skills" icon={<SchoolOutlinedIcon />} />
          </li>
          <li className="nav-tile">
            <NavButton value="Experience" icon={<ApartmentOutlinedIcon />} />
          </li>
          <li className="nav-tile">
            <NavButton
              value="Portfolio"
              icon={<BusinessCenterOutlinedIcon />}
            />
          </li>
          <li className="nav-tile">
            <NavButton value="Contact" icon={<AlternateEmailOutlinedIcon />} />
          </li>
        </ul>
      </div>
      <div className="socials">
        <li>
          <a
            target="_blank"
            href="mailto:arjunkhetia@gmail.com"
            rel="noreferrer"
          >
            <EmailOutlinedIcon className="contact-icon" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://www.instagram.com/arjunkhetia"
            rel="noreferrer"
          >
            <InstagramIcon className="contact-icon" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://github.com/arjunkhetia"
            rel="noreferrer"
          >
            <GitHubIcon className="contact-icon" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/arjun-khetia-32527a54"
            rel="noreferrer"
          >
            <LinkedInIcon className="contact-icon" />
          </a>
        </li>
      </div>
    </section>
  );
};
