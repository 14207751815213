import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";

const ProjectCard = ({
  title,
  description,
  image,
  link = null,
  github = null,
}) => {
  return (
    <div className="proj-card">
      <div className="img-container">
        <img className="blur-edges" src={image} alt="Project" />
        <img src={image} alt="Project" />
      </div>
      <div className="card-details">
        <h2 className="text-center">{title}</h2>
        <p>{description}</p>
        <div className="center">
          {link && (
            <a
              target="_blank"
              href={link}
              className="view-btn"
              rel="noreferrer"
            >
              View
              <LaunchOutlinedIcon className="view-icon" />
            </a>
          )}
          &nbsp; &nbsp;
          {github && (
            <a
              target="_blank"
              href={github}
              className="github-btn"
              rel="noreferrer"
            >
              Code
              <CodeOutlinedIcon className="view-icon" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
export default ProjectCard;
