import hand from "../assets/images/hand.gif";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import FamilyRestroomOutlinedIcon from "@mui/icons-material/FamilyRestroomOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import InsertEmoticonOutlinedIcon from "@mui/icons-material/InsertEmoticonOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";
import paper1 from "../assets/documents/paper-1.pdf";
import paper2 from "../assets/documents/paper-2.pdf";
import paper3 from "../assets/documents/paper-3.pdf";

const AboutMe = () => {
  const getSpans = (text) => {
    let spans = [];
    for (let i = 0; i < text.length; i++) {
      spans.push(
        <span className="t" key={i.toString()}>
          {text[i]}
        </span>
      );
    }
    return spans;
  };
  return (
    <section className="overflow-auto">
      <h1 className="about-title">{getSpans("About me")}</h1>
      <div className="about-contents">
        <h3>
          Hi There <img src={hand} width="35" height="35" alt="hand" />{" "}
        </h3>
        &nbsp; &nbsp; I'm <b className="color">Arjun Khetia</b> (Full Stack Web
        Developer) specialized in frontend & backend technologies. I have 8+
        years of hands-on industrial experience in web development, which
        includes Angular & React in frontend and Node.Js with Express framework
        in backend. Also having expertise in working with different types of
        database like MySQL, MongoDB, GraphQL, etc.
        <br />
        <h4>Personal Information</h4>
        <div className="personal-info">
          <TodayOutlinedIcon className="m-15" /> Date of Birth : 28/03/1990
        </div>
        <div className="personal-info">
          <SchoolOutlinedIcon className="m-15" /> Education : M.E. (VLSI &
          Embedded Systems)
        </div>
        <div className="personal-info">
          <TranslateOutlinedIcon className="m-15" /> Languages Known : English,
          Hindi, Gujarati
        </div>
        <div className="personal-info">
          <FamilyRestroomOutlinedIcon className="m-15" /> Marital Status :
          Married
        </div>
        <div className="personal-info">
          <FlagOutlinedIcon className="m-15" /> Nationality : Indian
        </div>
        <div className="personal-info">
          <InsertEmoticonOutlinedIcon className="m-15" /> Hobbies : Reading
          cultural books & Skating
        </div>
        <h3>Paper Published</h3>
        <div className="personal-info">
          <ArticleOutlinedIcon className="m-15" /> Multi Input Audio Synthesizer
          with FM Radio Having Analog Processing With 6 Bands and Digital
          Processing With 13 Bands Equalizer Using Digital Signal Prosessing
          through Matlab (Simulink)
        </div>
        <div className="publisher">
          International Journal of Futuristic Trends in Engineering and
          Technology - Sep 1, 2014{" "}
          <a
            without="true"
            target="_blank"
            href={paper1}
            className="show-publisher"
            rel="noopener noreferrer"
          >
            Show Publication <OpenInNewOutlinedIcon className="pub-icon" />{" "}
          </a>
        </div>
        <div className="personal-info">
          <ArticleOutlinedIcon className="m-15" /> PERFORMANCE AND AREA
          IMPROVEMENT OF PROGRAMMABLE LOGIC CONTROLLER (PLC) USING SYSTEM ON
          PROGRAMMABLE CHIP (SOPC)
        </div>
        <div className="publisher">
          American International Journal of Research in Science, Technology,
          Engineering & Mathematics - May 15, 2015{" "}
          <a
            without="true"
            target="_blank"
            href={paper2}
            className="show-publisher"
            rel="noopener noreferrer"
          >
            Show Publication <OpenInNewOutlinedIcon className="pub-icon" />{" "}
          </a>
        </div>
        <div className="personal-info">
          <ArticleOutlinedIcon className="m-15" /> Design and Implementation of
          Programmable Logic Controller (PLC) Using System on Programmable Chip
          (SOPC)
        </div>
        <div className="publisher">
          International Journal of Innovative Research in Computer and
          Communication Engineering - Aug 10, 2015{" "}
          <a
            without="true"
            target="_blank"
            href={paper3}
            className="show-publisher"
            rel="noopener noreferrer"
          >
            Show Publication <OpenInNewOutlinedIcon className="pub-icon" />{" "}
          </a>
        </div>
        <h3>Patent Registered</h3>
        <div className="personal-info">
          <MilitaryTechOutlinedIcon className="m-15" /> <b className="color">AYUYANTRA</b>&nbsp;- Novel Diagnostic tool based on Ayurvedic System
        </div>
        <div className="publisher">2372/mum/2012 - Aug 15, 2012</div>
      </div>
    </section>
  );
};

export default AboutMe;
