import { useState, useEffect } from "react";
import diksha from "../assets/images/diksha.png";
import kornferry from "../assets/images/kornferry.png";
import crossbowlabs from "../assets/images/crossbowlabs.png";
import t3micro from "../assets/images/t3micro.png";
import primesuperdeals from "../assets/images/primesuperdeals.png";
import salud360 from "../assets/images/salud360.png";
import musicmunch from "../assets/images/musicmunch.png";
import manthansetupath from "../assets/images/manthansetupath.png";
import chhotanivesh from "../assets/images/chhotanivesh.png";
import finalee from "../assets/images/finalee.png";
import digiverifier from "../assets/images/digiverifier.png";

const useProjects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    let project = [
      {
        title: "Diksha",
        description:
          "DIKSHA (Digital Infrastructure for Knowledge Sharing) is a national platform for school education, an initiative of National Council for Educational Research and Training (NCERT), under the aegis of the Ministry of Education (Ministry of Education, Govt of India). The Diksha platform offers engaging learning material relevant to the prescribed school curriculum to teachers, students and parents.",
        image: diksha,
        link: "https://diksha.gov.in",
        // github: "https://github.com/teshank2137/fanMerch",
      },
      {
        title: "Korn Ferry",
        description:
          "Korn Ferry is a global organizational consulting firm. We work with our clients to design optimal organization structures, roles, and responsibilities. We help them hire the right people and advise them on how to reward and motivate their workforce while developing professionals as they navigate and advance their careers.",
        image: kornferry,
        link: "https://www.kornferry.com",
      },
      {
        title: "Crossbow Labs",
        description:
          "We are a team of cyber security experts and enthusiasts who have experience in consulting on various cyber security standards & compliance programs. Crossbow Labs has customers in over 30 countries globally with 250 customers for various cyber security consulting and implementation programs in Banking, Retail, Fintech, Hospitality, E-commerce, IT/ITES, BPO, Manufacturing and emerging technologies.",
        image: crossbowlabs,
        link: "https://crossbowlabs.com",
      },
      {
        title: "T3 Micro",
        description:
          "T3 ignites your styling imagination with next-gen technology to transform the styling experience and deliver the hair you love. In 2003, part tech start-up, part beauty venture, T3 set out to reinvent hair styling by pioneering a new kind of hair tool: technology-forward, fast-working, designed with high performance and better results in mind.",
        image: t3micro,
        link: "https://www.t3micro.com",
      },
      {
        title: "Prime Super Deals",
        description:
          "The one thing all businesses and marketers have in common is the ongoing need for graphics in their campaigns. DesignBundle gives you the competitive edge you need to create stunning graphics in any market like Landing Page  Builder, Graphics Editor, Flexible Images, Mockup & Scene Creator, Logo & Video 2 GIF Creator, FX Visual Clips, etc.",
        image: primesuperdeals,
        link: "https://primesuperdeals.store",
      },
      {
        title: "Salud 360",
        description:
          "Monthly or annual payment health plan that offers coverage and discounts on medical services as well as great additional benefits throughout the national territory to its clients. Created by leading opinion doctors from Costa Rica with the aim of providing the best service and well-being to the Costa Rican population.",
        image: salud360,
        link: "https://www.salud360.cr",
      },
      {
        title: "Music Munch",
        description:
          "Introducing the groundbreaking 'Rellek VST'. This is a fully functional VST that works in all DAW software such as FL Studio, Logic, Cubase, Studio One, Ableton and more! 'Savage Of The Future' is a new series aimed at producers' demands for the newest trends in the industry.",
        image: musicmunch,
        link: "https://musicmunch.tappit.co",
      },
      {
        title: "Manthan Setu Path",
        description:
          "This is infrastructure consultancy firm and founded in the year 2020. This organization is Partnership firm have vast experience in Detail Project Report, Design, Drawing, Project Management Consultancy. Founder of this firm have 36 years Railway , National Highway, Metro, Major Bridges, Flyovers, Buildings, Rail Coach factory, Industry and Hospital Projects experience from design to Hand over to Client in India, Nepal, Malaysia, Bangladesh.",
        image: manthansetupath,
        link: "http://www.manthansetupath.com",
      },
      {
        title: "Chhota Nivesh",
        description:
          "Chhota Nivesh Gold is the inclusive investment platform for everyone who aims to invest small but wants to earn good returns. Hence its financial products are for everyone to use and invest in with the smallest amount. Under Chhota Nivesh Gold , you can invest in any product for even Re. 1. This makes the most democratic investment platform as well as flexible for anyone who wants to begin investment either in gold, silver or any other financial product.",
        image: chhotanivesh,
        link: "https://chhotaniveshgold.com",
      },
      {
        title: "Finalee",
        description:
          "The world's fastest and most accurate app for finding what's nearby. Swipe to change to a different search category. Tap to filter between all the places you've been, haven't been, or both. Scroll the list of businesses to see all that are listed within the miles searched.",
        image: finalee,
        link: "https://finalee.com",
      },
      {
        title: "DigiVerifier",
        description:
          "A Licensed Company Empowering Recruiters With Best Digital Background Verification Solutions. Choose Digiverifier For Proper Digital Background Verification Process, Digiverifier is a brand of CROSSBOW.",
        image: digiverifier,
        link: "https://www.digiverifier.com",
      },
    ];
    setProjects(project);
  }, []);

  return projects;
};

export default useProjects;
