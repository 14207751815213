import { StyledAbout } from "./styles/StyledAbout";
import AboutMe from "../sections/AboutMe";
import arjun from "../assets/images/arjun.png";
import arjunmob from "../assets/images/arjun-mob.png";
import useIsMobile from "../customHooks/useIsMobile";

const About = () => {
  const ismobile = useIsMobile();
  return (
    <StyledAbout className="about">
      <img
        className={ismobile ? "arjun-mob" : ""}
        src={ismobile ? arjunmob : arjun}
        alt="Arjun Khetia"
      />
      <AboutMe />
    </StyledAbout>
  );
};

export default About;
