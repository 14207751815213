import StyledCard from "./CardStyled";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";

const JobCard = ({
  position,
  compName,
  timestamp,
  description,
  projects,
  technologies,
}) => {
  return (
    <StyledCard>
      <div className="card">
        <h2 className="card-title color">{position}</h2>
        <div className="card-body">
          <h3 className="card-compName">
            <ApartmentOutlinedIcon className="sub-icon" />
            {compName}
          </h3>
          <div className="card-timestamp">{timestamp}</div>
          <div className="card-description">
            <BadgeOutlinedIcon className="sub-icon" />
            {description}
          </div>
          <h4>Projects - </h4>
          <div className="u-5">
            {projects.map((e, i) => (
              <span key={i}>
                <SourceOutlinedIcon className="project-icon" />
                {e} <br />
              </span>
            ))}
          </div>
          <h4>Technologies - </h4>
          <div className="u-10">
            {technologies.map((e, i) => (
              <span key={i}>{e}</span>
            ))}
          </div>
        </div>
      </div>
    </StyledCard>
  );
};

export default JobCard;
