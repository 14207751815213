import WebOutlinedIcon from "@mui/icons-material/WebOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import angular from "../assets/icons/angular.png";
import react from "../assets/icons/react.png";
import node from "../assets/icons/node.png";
import express from "../assets/icons/expressjs.png";
import mysql from "../assets/icons/mysql.png";
import mongodb from "../assets/icons/mongodb.png";
import redis from "../assets/icons/redis.png";
import aws from "../assets/icons/aws.png";
import cassandra from "../assets/icons/cassandra.png";
import graphql from "../assets/icons/graphql.png";
import jira from "../assets/icons/jira.png";
import swagger from "../assets/icons/swagger.png";
import git from "../assets/icons/git.png";
import vscode from "../assets/icons/vscode.png";
import datastaxstudio from "../assets/icons/datastax-studio.png";
import apollo from "../assets/icons/apollo.png";
import kafka from "../assets/icons/apache-kafka.png";
import useIsMobile from "../customHooks/useIsMobile";

const SkillsText = () => {
  const ismobile = useIsMobile();
  const getSpans = (text) => {
    let spans = [];
    for (let i = 0; i < text.length; i++) {
      spans.push(
        <span className="t" key={i.toString()}>
          {text[i]}
        </span>
      );
    }
    return spans;
  };
  return (
    <section className="skill-text">
      <h1 className="skill-title">{getSpans("Skills").map((e) => e)}</h1>
      <div className="skill-contents">
        <h4>Frontend</h4>
        <table>
          <tbody>
            <tr>
              <td>
                <div
                  className={ismobile ? "mobile-skill-label" : "skill-label"}
                >
                  <WebOutlinedIcon className="m-15" /> Web Technology
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img
                    src={angular}
                    className="m-215"
                    width="35"
                    alt="Angular"
                  />
                  Angular
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img src={react} className="m-215" width="35" alt="React" />
                  React
                </div>
              </td>
              <td>
                <div
                  className={ismobile ? "mobile-skill-label" : "skill-label"}
                >
                  <PhoneIphoneOutlinedIcon className="m-15" /> Mobile Technology
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img
                    src={react}
                    className="m-215"
                    width="35"
                    alt="React Native"
                  />
                  React Native
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <h4>Backend</h4>
        <table>
          <tbody>
            <tr>
              <td>
                <div
                  className={ismobile ? "mobile-skill-label" : "skill-label"}
                >
                  <MemoryOutlinedIcon className="m-15" /> Server Technology
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img src={aws} className="m-215" width="35" alt="AWS" />
                  AWS
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img src={node} className="m-215" width="35" alt="Node.Js" />
                  Node.Js
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img
                    src={express}
                    className="m-215"
                    width="35"
                    alt="Express"
                  />
                  Express
                </div>
              </td>
              <td>
                <div
                  className={ismobile ? "mobile-skill-label" : "skill-label"}
                >
                  <StorageOutlinedIcon className="m-15" /> Database Technology
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img src={mysql} className="m-215" width="35" alt="MySQL" />
                  MySQL
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img
                    src={mongodb}
                    className="m-215"
                    width="35"
                    alt="MongoDB"
                  />
                  MongoDB
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img src={redis} className="m-215" width="35" alt="Redis" />
                  Redis
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img
                    src={cassandra}
                    className="m-215"
                    width="35"
                    alt="Cassandra"
                  />
                  Cassandra
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img
                    src={graphql}
                    className="m-215"
                    width="35"
                    alt="GraphQL"
                  />
                  GraphQL
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <h4>Development Tools</h4>
        <table>
          <tbody>
            <tr>
              <td>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img src={jira} className="m-215" width="35" alt="Jira" />
                  Jira
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img
                    src={swagger}
                    className="m-215"
                    width="35"
                    alt="Swagger"
                  />
                  Swagger
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img
                    src={datastaxstudio}
                    className="m-215"
                    width="35"
                    alt="Datastax Studio"
                  />
                  Datastax Studio
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img
                    src={apollo}
                    className="m-215"
                    width="35"
                    alt="Apollo Studio"
                  />
                  Apollo Studio
                </div>
              </td>
              <td>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img src={git} className="m-215" width="35" alt="Git" />
                  Git
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img
                    src={vscode}
                    className="m-215"
                    width="35"
                    alt="Visual Studio Code"
                  />
                  Visual Studio Code
                </div>
                <div className={ismobile ? "skill-info" : "personal-info"}>
                  <img
                    src={kafka}
                    className="m-215"
                    width="35"
                    alt="Apache Kafka"
                  />
                  Apache Kafka
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default SkillsText;
