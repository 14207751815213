import styled from "styled-components";
import { type, screenSize } from "../../utils";
const StyledPortfolio = styled.div`
  h1 {
    margin: 0;
  }
  .title {
    font-size: ${type.webTitle};
    padding: 2.25rem;
    padding-right: 0;
  }
  @media only screen and (max-width: ${screenSize.mobile}) {
    .title,
    .title {
      padding-top: 2rem;
      font-size: ${type.webTitleMobile};
    }
  }
`;

export default StyledPortfolio;
