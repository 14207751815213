import { useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import computerLaptopDesk from "../assets/3d/computer-laptop.glb";
import computerDesk from "../assets/3d/computer-desk.glb";
import useIsMobile from "../customHooks/useIsMobile";

const ComputerDesk = () => {
    const ismobile = useIsMobile();
    const gltf = useLoader(GLTFLoader, ismobile ? computerDesk : computerLaptopDesk);
    const ref = useRef();
    useFrame(({ clock }) => {
        ref.current.rotation.y =
            (ismobile ? -15 : -70) + Math.sin(clock.getElapsedTime() * 0.2) * 0.4 + 0.9;
        ref.current.rotation.x = 0.35;
    });
    return (
        <>
            <ambientLight intensity={1} />
            {ismobile && <>
                <directionalLight position={[2, 2, 0]}/>
                <directionalLight position={[2, 0, 2]}/>
                <directionalLight position={[0, 2, 2]}/>
                <directionalLight position={[2, 2, 2]}/>
                <directionalLight position={[-2, 2, 2]}/>
                <directionalLight position={[2, -2, 2]}/>
            </>}
            <primitive
                object={gltf.scene}
                position={[0, 0, 0]}
                scale={ismobile ? 0.25 : 0.08}
                ref={ref}
            />
        </>
    );
};

export default ComputerDesk;
