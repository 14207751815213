import StyledSkill from "./styles/StyledSkill";
import SkillsText from "../sections/SkillsText";
import Skills3D from "../sections/Skills3D";

const Skills = () => {
  return (
    <StyledSkill>
      <div className="skill-container">
        <SkillsText />
        <Skills3D />
      </div>
    </StyledSkill>
  );
};

export default Skills;
