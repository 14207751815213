import JobCard from "../components/Card";
import ReactCardCarousel from "react-card-carousel";
import StyledExperience from "./styles/StyledExperience";
import bootstrap from "../assets/icons/bootstrap.svg";
import materialdesign from "../assets/icons/material-design.png";
import angular from "../assets/icons/angular.png";
import react from "../assets/icons/react.png";
import node from "../assets/icons/node.png";
import express from "../assets/icons/expressjs.png";
import mongodb from "../assets/icons/mongodb.png";
import redis from "../assets/icons/redis.png";
import graphql from "../assets/icons/graphql.png";
import swagger from "../assets/icons/swagger.png";
import cassandra from "../assets/icons/cassandra.png";
import useIsMobile from "../customHooks/useIsMobile";

const Experience = () => {
  const ismobile = useIsMobile();
  const getSpans = (text) => {
    let spans = [];
    for (let i = 0; i < text.length; i++) {
      spans.push(
        <span className="t" key={i.toString()}>
          {text[i]}
        </span>
      );
    }
    return spans;
  };
  return (
    <StyledExperience>
      <div className="experience-container">
        <h1 className="experience-title">
          {getSpans("Experience").map((e) => e)}
        </h1>
        <div className="cards">
          <ReactCardCarousel
            spread={ismobile ? "narrow" : "wide"}
            autoplay="true"
            autoplay_speed="10000"
          >
            <JobCard
              position="Full Stack Developer (Module Lead)"
              compName="DXC Technology (HP)"
              timestamp="Sept 2022 - Present"
              description="Designed Front-end & also implemented back-end and coded business logic with API Formulation & Implementation."
              projects={[
                "Inchcape - Digital Parts Platform",
                "Rate Card Portal - Paris 2024",
              ]}
              technologies={[
                <img src={angular} className="m-15" width="35" alt="Angular" />,
                <img
                  src={bootstrap}
                  className="m-15"
                  width="35"
                  alt="Bootstrap"
                />,
                <img src={node} className="m-15" width="35" alt="Node.Js" />,
                <img src={express} className="m-15" width="35" alt="Express" />,
                <img src={swagger} className="m-15" width="35" alt="Swagger" />,
                <img src={graphql} className="m-15" width="35" alt="GraphQL" />,
                <img src={mongodb} className="m-15" width="35" alt="MongoDB" />,
              ]}
            />
            <JobCard
              position="Full Stack Developer (Module Lead)"
              compName="CenturyLink (Lumen)"
              timestamp="Feb 2021 - May 2022"
              description="Designed Front-end & also implemented back-end and coded business logic with API Formulation & Implementation."
              projects={[
                "Universal-EV",
                "Citus HealthCare",
                "Figpin",
                "Chhota Nivesh",
              ]}
              technologies={[
                <img src={angular} className="m-15" width="35" alt="Angular" />,
                <img
                  src={bootstrap}
                  className="m-15"
                  width="35"
                  alt="Bootstrap"
                />,
                <img src={node} className="m-15" width="35" alt="Node.Js" />,
                <img src={express} className="m-15" width="35" alt="Express" />,
                <img src={swagger} className="m-15" width="35" alt="Swagger" />,
                <img src={graphql} className="m-15" width="35" alt="GraphQL" />,
                <img src={mongodb} className="m-15" width="35" alt="MongoDB" />,
              ]}
            />
            <JobCard
              position="Full Stack Developer (Team Lead)"
              compName="StackRoute Labs (NIIT)"
              timestamp="Apr 2019 - Jul 2020"
              description="Designed Front-end & also implemented back-end and coded business logic with API Formulation & Implementation."
              projects={[
                "Sunbird (Open Source)",
                "Diksha (Indian Government Project)",
              ]}
              technologies={[
                <img src={angular} className="m-15" width="35" alt="Angular" />,
                <img
                  src={materialdesign}
                  className="m-15"
                  width="35"
                  alt="Material Design"
                />,
                <img src={react} className="m-15" width="35" alt="React" />,
                <img src={node} className="m-15" width="35" alt="Node.Js" />,
                <img src={express} className="m-15" width="35" alt="Express" />,
                <img src={swagger} className="m-15" width="35" alt="Swagger" />,
                <img src={redis} className="m-15" width="35" alt="Redis" />,
              ]}
            />
            <JobCard
              position="Full Stack Developer"
              compName="Moback Technologies"
              timestamp="Oct 2017 - Feb 2019"
              description="Designed Front-end & also implemented back-end and coded business logic with API Formulation & Implementation."
              projects={[
                "Resource Desktop",
                "Korn Ferry Direct",
                "Finalee",
                "Mojack",
              ]}
              technologies={[
                <img src={angular} className="m-15" width="35" alt="Angular" />,
                <img
                  src={bootstrap}
                  className="m-15"
                  width="35"
                  alt="Bootstrap"
                />,
                <img
                  src={materialdesign}
                  className="m-15"
                  width="35"
                  alt="Material Design"
                />,
                <img src={node} className="m-15" width="35" alt="Node.Js" />,
                <img src={express} className="m-15" width="35" alt="Express" />,
                <img src={swagger} className="m-15" width="35" alt="Swagger" />,
                <img src={mongodb} className="m-15" width="35" alt="MongoDB" />,
              ]}
            />
            <JobCard
              position="Software Engineer"
              compName="Talent & Analytics India Pvt. Ltd."
              timestamp="Jan 2017 - Sep 2017"
              description="Designed Front-end & also implemented back-end and coded business logic with API Formulation & Implementation."
              projects={["Crossbow - DigiVerifier"]}
              technologies={[
                <img src={angular} className="m-15" width="35" alt="Angular" />,
                <img
                  src={bootstrap}
                  className="m-15"
                  width="35"
                  alt="Bootstrap"
                />,
                <img src={node} className="m-15" width="35" alt="Node.Js" />,
                <img src={express} className="m-15" width="35" alt="Express" />,
                <img src={swagger} className="m-15" width="35" alt="Swagger" />,
                <img
                  src={cassandra}
                  className="m-15"
                  width="35"
                  alt="Cassandra"
                />,
              ]}
            />
            <JobCard
              position="Full Stack Developer"
              compName="Gujju Coders"
              timestamp="Mar 2015 - Jan 2017"
              description="Designed Front-end & also implemented back-end and coded business logic with API Formulation & Implementation."
              projects={["Child Safe Guard", "Advanced Mobile Jammer"]}
              technologies={[
                <img src={angular} className="m-15" width="35" alt="Angular" />,
                <img
                  src={bootstrap}
                  className="m-15"
                  width="35"
                  alt="Bootstrap"
                />,
                <img src={node} className="m-15" width="35" alt="Node.Js" />,
                <img src={express} className="m-15" width="35" alt="Express" />,
                <img src={swagger} className="m-15" width="35" alt="Swagger" />,
                <img src={mongodb} className="m-15" width="35" alt="MongoDB" />,
              ]}
            />
          </ReactCardCarousel>
        </div>
      </div>
    </StyledExperience>
  );
};

export default Experience;
