import { useState, useEffect } from "react";
import useIsMobile from "../customHooks/useIsMobile";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const ContactForm = () => {
  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);
  const [msgSent, setMsgSent] = useState(false);
  const [element, setElement] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [mobile, setMobile] = useState("");
  const [isError, setIsError] = useState(true);
  const isMobile = useIsMobile();
  const sendMessage = async () => {
    setSending(true);
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const data = JSON.stringify({
      name: name,
      email: email,
      mobile: mobile,
      message: message,
      type: "portfolio",
    });
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: data,
    };
    fetch(
      "https://giekuaivv9.execute-api.us-east-1.amazonaws.com/emancitech",
      requestOptions
    )
      .then(() => {
        setTimeout(() => {
          setName("");
          setEmail("");
          setMobile("");
          setMessage("");
          setMsgSent(true);
          setSending(false);
        }, 2000);
      })
      .catch(() => {
        setError(true);
        setTimeout(() => {
          setError(false);
          setSending(false);
        }, 5000);
      });
  };
  useEffect(() => {
    if (name.length > 0 && email.length > 0 && message.length > 0) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  }, [name, email, message]);
  return (
    <div className="contact-form">
      <div className="form-group">
        <div className="left-icon name-inpt">
          <PersonOutlineOutlinedIcon
            className={element === "name" ? "icon icon-color" : "icon"}
          />
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Name"
            value={name}
            onFocus={() => setElement("name")}
            onBlur={() => setElement("")}
            onChange={(e) => setName(e.target.value)}
            autoComplete="off"
          />
        </div>
        <div className="left-icon">
          <PhoneOutlinedIcon
            className={element === "mobile" ? "icon icon-color" : "icon"}
          />
          <input
            type="text"
            className="form-control"
            id="mobile"
            name="mobile"
            placeholder="Contact Number"
            value={mobile}
            onFocus={() => setElement("mobile")}
            onBlur={() => setElement("")}
            onChange={(e) => setMobile(e.target.value)}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="left-icon form-group">
        <EmailOutlinedIcon
          className={element === "email" ? "icon icon-color" : "icon"}
        />
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          placeholder="E-mail"
          value={email}
          onFocus={() => setElement("email")}
          onBlur={() => setElement("")}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="off"
        />
      </div>
      <div className="form-group">
        <div className="left-icon">
          <MessageOutlinedIcon
            className={
              element === "message" ? "mesg-icon icon-color" : "mesg-icon"
            }
          />
          <textarea
            className="form-control"
            id="message"
            name="message"
            rows={isMobile ? "4" : "6"}
            placeholder="Enter your message"
            value={message}
            onFocus={() => setElement("message")}
            onBlur={() => setElement("")}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
      </div>
      <div className="btn-grp">
        {!msgSent && !sending && !error && (
          <button className="send-btn" disabled={isError} onClick={sendMessage}>
            Send Message
            <SendOutlinedIcon className="send-arrow" />
          </button>
        )}
        {msgSent && !sending && !error && (
          <button className="sent-btn">Message Sent</button>
        )}
        {sending && !error && (
          <button className="sending-btn">
            Sending <CachedOutlinedIcon className="sending-circle" />
          </button>
        )}
        {error && (
          <button className="error-btn">
            Error in sending message
            <ErrorOutlineOutlinedIcon className="send-arrow" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
