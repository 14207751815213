import { useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import penPaper from "../assets/3d/pen-paper.glb";
import useIsMobile from "../customHooks/useIsMobile";

const PenPaper = () => {
  const gltf = useLoader(GLTFLoader, penPaper);
  const ref = useRef();
  const ismobile = useIsMobile();
  useFrame(() => {
    ref.current.rotation.y += 0.005;
    ref.current.rotation.x = 0.3;
  });
  return (
    <>
      <ambientLight intensity={ismobile ? 1 : 0.8} />
      <primitive
        object={gltf.scene}
        position={ismobile ? [0, 2, 0] : [0, 2, 0]}
        scale={ismobile ? 0.25 : 0.2}
        ref={ref}
      />
    </>
  );
};

export default PenPaper;
