import styled from "styled-components";
import { type, screenSize } from "../../utils/typography";

const StyledSkill = styled.div`
  .skill-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
  }

  .skill-text {
    width: 50%;
    height: 100vh;
    overflow: auto;
  }

  .about-3d {
    width: 50%;
    height: 100vh;
  }

  .skills-3d {
    height: 100%;
  }

  .skill-title {
    font-size: ${type.webTitle};
    padding: 2.25rem;
    padding-right: 0;
    margin: 0;
    padding-bottom: 0rem;
  }

  .skill-contents {
    font-size: ${type.bodyText};
    padding: 2.25rem;
    padding-top: 0rem;
  }

  @media only screen and (max-width: ${screenSize.tablet}) {
    .skill-contents {
      font-size: calc(${type.bodyTextMobile} * 1.4);
    }
  
    .skill-title {
      font-size: calc(${type.webTitle} * 0.8);
    }
  }

  @media only screen and (max-width: ${screenSize.mobile}) {
    .skill-container {
      flex-direction: column;
      height: auto;
    }
  
    .skill-text {
      width: 100%;
      height: auto;
      overflow: unset;
    }
  
    .skill-title {
      font-size: ${type.webTitleMobile};
      padding: 1.1rem;
      padding-top: 2rem;
    }
  
    .skill-contents {
      font-size: ${type.bodyTextMobile};
      padding: 1.1rem;
      padding-top: 0.7rem;
    }
  
    .about-3d {
      width: 100%;
      height: 100vh;
    }

    .skills-3d {
      pointer-events: none;
    }
  
    td {
      display: block;
      width: 100%
    }
  }
`;

export default StyledSkill;
