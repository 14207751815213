import MenuMobile from "./components/mobile-navigation";
import Home from "./pages/Home";
import { NavBar } from "./sections/navbar";
import { Suspense, useState, useEffect } from "react";
import Loading from "./pages/Loading";
import About from "./pages/About";
import Skills from "./pages/Skills";
import Experience from "./pages/Experience";
import Portfolio from "./pages/Portfolio";
import ContactPage from "./pages/Contact";

function App() {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  });
  return (
    <div className="App">
      <NavBar />
      <MenuMobile />
      <div className="main">
        <div className="container" id="Home">
          <Suspense fallback={<Loading />}>
            { isLoading ? <Loading /> : <Home /> }
          </Suspense>
        </div>
        <div className="container" id="About">
          <About />
        </div>
        <div className="container" id="Skills">
          <Skills />
        </div>
        <div className="container" id="Experience">
          <Experience />
        </div>
        <div className="container" id="Portfolio">
          <Portfolio />
        </div>
        <div className="contact-container" id="Contact">
          <ContactPage />
        </div>
        <footer className="footer footer-container">
          <div>
            Copyright &copy; {new Date().getFullYear()} | All Rights Reserved 
            <span className="companyname">&nbsp;
              <a target="_blank" rel="noreferrer" href="https://www.emancitech.com">
                EmanciTech
              </a>
            </span>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
